
import { Options } from "vue-class-component";
import ComponentMixins from "../ComponentMixins";
import PostPickerComponent from "../../../components/PostPicker/PostPicker.vue";

@Options({
    name: "flowable-common-form-components-post-picker",
    components: { PostPicker: PostPickerComponent },
    props: {
        placeholder: {
            type: String,
            default: "请选择岗位",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        readerMode: {
            type: Boolean,
            default: false,
        },
    },
})
export default class PostPicker extends ComponentMixins {
    public selected(values: any) {
        this._value = values;
    }
    public delPost(i: any) {
        this._value.splice(i, 1);
    }
}
